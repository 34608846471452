import { createStore } from 'vuex';
import users from './users';
import plans from './plans';
import tags from './tags';
import auth from './auth';
import crm from './crm';
import furniture from './furniture';
import svg from './svg';
import substances from './substances';
import materials from './materials';
import locale from './locale';

export default createStore({
  state: {
    isResponsive: false,
  },
  mutations: {
    setResponsive(state, isResponsive) {
      state.isResponsive = isResponsive;
    },
  },
  actions: {
  },
  modules: {
    users,
    plans,
    tags,
    auth,
    crm,
    furniture,
    svg,
    substances,
    locale,
    materials,
  },
});
