import { api } from '@/api';

export default {
  namespaced: true,
  state: {
    items: [],
    tags: [],
    isLoading: false,

    current: {
      isLoading: false,
    },
    currentTopViewUrl: null,
    designFiles: [],
    deletedItems: [],
  },
  mutations: {
    setMaterials(state, items) {
      state.items = items;
    },
    setMaterialsTags(state, tags) {
      state.tags = tags;
    },
    setLoading(state, loading) {
      state.isLoading = loading;
    },
    setCurrentMaterial(state, data) {
      state.current = {
        ...state.current,
        ...data,
      };
    },
    setCurrentLoading(state, loading) {
      state.current.isLoading = loading;
    },
    setDeletedMaterial(state, deletedItems) {
      state.deletedItems = deletedItems;
    },
  },
  actions: {

    getList(store) {
      store.commit('setLoading', true);

      const params = { page: store.state.page, is_archived: false };

      return api.getMaterialsList(params).then(({ data }) => {
        store.commit('setMaterials', data);
        store.commit('setLoading', false);
      });
    },

    getDeletedMaterialsList(store) {
      store.commit('setLoading', true);

      const params = { page: store.state.page, is_archived: true };

      return api.getMaterialsList(params).then(({ data }) => {
        store.commit('setDeletedMaterial', data);
        store.commit('setLoading', false);
      });
    },

    getMaterialsTags(store) {
      store.commit('setLoading', true);

      const params = { page: store.state.page };

      return api.getMaterialsTags(params).then(({ data }) => {
        store.commit('setMaterialsTags', data);
        store.commit('setLoading', false);
      });
    },

    async getMaterial({ commit }, id) {
      const response = await api.getMaterialById(id);
      commit('setCurrentMaterial', response.data);
      return response.data;
    },

    async updateMaterialPlanStyles({ commit, dispatch }, { id, planStyles }) {
      await api.updateMaterialPlanStyles(id, planStyles);
      const updatedMaterial = await dispatch('getMaterial', id);

      commit('setCurrentMaterial', updatedMaterial);
      return updatedMaterial;
    },

    async updateMaterialRoomTypes({ commit, dispatch }, { id, roomTypes }) {
      await api.updateMaterialRoomTypes(id, roomTypes);
      const updatedMaterial = await dispatch('getMaterial', id);

      commit('setCurrentMaterial', updatedMaterial);
      return updatedMaterial;
    },

    async updateMaterialTags({ commit, dispatch }, { id, tags }) {
      await api.updateMaterialTags(id, tags);
      const updatedMaterial = await dispatch('getMaterial', id);

      commit('setCurrentMaterial', updatedMaterial);
      return updatedMaterial;
    },

    deleteMaterialById({ commit, state, dispatch }, id) {
      return api.deleteMaterial(id).then(() => {
        const updatedMaterials = state.items.filter((item) => item.id !== id);

        commit('setMaterials', updatedMaterials);

        return dispatch('getDeletedMaterialsList');
      }).catch((error) => {
        console.error('Ошибка при удалении материала:', error);
        throw error;
      });
    },

    restoreMaterial({ commit, state, dispatch }, id) {
      return api.restoreMaterial(id, { is_archived: false }).then(() => {
        const updatedDeletedItems = state.deletedItems.filter((item) => item.id !== id);

        commit('setDeletedMaterial', updatedDeletedItems);

        return dispatch('getList');
      }).catch((error) => {
        console.error('Ошибка при восстановлении материала:', error);
        throw error;
      });
    },

    createMaterialTag(store, request) {
      return api.createMaterialTag(request).catch((error) => {
        console.error('Ошибка при создании тега:', error);
        throw error;
      });
    },

  },
};
