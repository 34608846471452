import { api } from '@/api';

export default {
  namespaced: true,
  state: {
    usersList: {
      isLoading: false,
      total: 0,
      page: 1,
      perPage: 15,
      items: [],
    },
    deletedUsersList: {
      isLoading: false,
      total: 0,
      page: 1,
      perPage: 15,
      items: [],
    },
  },
  mutations: {
    setUsersListLoadingState(state, isLoading) {
      state.usersList = { ...state.usersList, isLoading };
    },
    setUsersList(state, data) {
      const items = data;

      state.usersList = {
        ...state.usersList,
        items,
      };
    },

    setUsersPage(state, page) {
      state.page = page;
    },
    setUsersTotal(state, total) {
      state.total = total;
    },
    setPerPage(state, perPage) {
      state.perPage = perPage;
    },

    setDeletedUsersListLoadingState(state, isLoading) {
      state.deletedUsersList = { ...state.deletedUsersList, isLoading };
    },
    setDeletedUsersList(state, data) {
      const items = data;

      state.deletedUsersList = {
        ...state.deletedUsersList,
        items,
      };
    },
  },
  actions: {
    async getUsersList(store, { page, filter, sort }) {
      if (store.state.usersList.isLoading) return store.state.usersList;

      store.commit('setUsersListLoadingState', true);
      return api.getUsersList({ page, filter, sort }).then((resp) => {
        const { data } = resp;
        store.commit('setUsersList', data);
        return data;
      }).finally(() => store.commit('setUsersListLoadingState', false));
    },
    async getDeletedUsersList(store, { page, filter, sort }) {
      if (store.state.deletedUsersList.isLoading) return store.state.deletedUsersList;

      store.commit('setDeletedUsersListLoadingState', true);
      return api.getDeletedUsersList({ page, filter, sort }).then((resp) => {
        const { data } = resp;
        store.commit('setDeletedUsersList', data);
        return data;
      }).finally(() => store.commit('setDeletedUsersListLoadingState', false));
    },
    changeRole(store, request) {
      return api.changeUserRole(request).then((resp) => resp);
    },
    changeName(store, request) {
      return api.changeUserName(request).then((resp) => resp);
    },
    deleteUser(store, id) {
      return api.deleteUser(id).then((resp) => {
        store.dispatch('getDeletedUsersList', 1);
        return resp;
      });
    },
    restoreUser(store, id) {
      return api.restoreUser(id).then((resp) => {
        store.dispatch('getUsersList', 1);
        return resp;
      });
    },
    changeNinjaStatus(store, { id, ninjaExpertLevel2d, ninjaExpertLevel3d }) {
      return api.changeNinjaStatus(
        id,
        ninjaExpertLevel2d,
        ninjaExpertLevel3d,
      ).then((resp) => resp);
    },
    changeBetaTesterStatus(store, { id, betaTester }) {
      return api.changeBetaTesterStatus(id, betaTester);
    },
    changeDatasetVoterStatus(store, { id, datasetVoter }) {
      return api.changeDatasetVoterStatus(id, datasetVoter);
    },
    changeCanGetPlansToWorkStatus(store, { id, canGetPlansToWork }) {
      return api.changeCanGetPlansToWorkStatus(id, canGetPlansToWork);
    },
    changePlansLimit(store, { id, plansLimit }) {
      return api.changePlansLimit(id, plansLimit);
    },
  },
};
